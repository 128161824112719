import React, { useEffect, useRef, useState } from 'react';
import banner01 from '../assets/images/EMR, Billing, Telemedicine.jpg';
import banner02 from '../assets/images/Medical Credentialing.jpg';
import banner03 from '../assets/images/Patient Relationship Management.jpg';
import whatWeDo from '../assets/images/what we do.jpg';
import prm from '../assets/images/prm.jpg';
import mcs from '../assets/images/mcs.jpg';
import bhc from '../assets/images/bhc.jpg';
import mbs from '../assets/images/mbs.jpg';



const Home = () => {
  const sliderRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const banners = [banner01, banner02, banner03];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
    }, 2000); // 2 seconds delay

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [banners.length]);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.scrollTo({
        left: sliderRef.current.offsetWidth * currentIndex,
        behavior: 'smooth',
      });
    }
  }, [currentIndex]);

  return (
    <div className="container mx-auto">
      {/* Slider Section */}
      <div className="w-full  overflow-hidden relative">
        <div
          className="flex w-full snap-x snap-mandatory overflow-x-scroll scrollbar-hide"
          ref={sliderRef}
        >
          {/* Slide 1 */}
          <div className="w-full flex-shrink-0 snap-center">
            <img
              src={banner01}
              alt="EMR, Billing, Telemedicine"
              className="w-full "
            />
          </div>

          {/* Slide 2 */}
          <div className="w-full flex-shrink-0 snap-center">
            <img
              src={banner02}
              alt="Medical Credentialing"
              className="w-full "
            />
          </div>

          {/* Slide 3 */}
          <div className="w-full flex-shrink-0 snap-center">
            <img
              src={banner03}
              alt="Patient Relationship Management"
              className="w-full "
            />
          </div>
        </div>
      </div>

      {/* What We Do Section */}
      <div className="px-4">
      <section className=" my-12 px-4 lg:flex lg:items-center lg:justify-between">
  {/* Image Section */}
  <div className="lg:w-1/2 mb-6 lg:mb-0">
    <img
      src={whatWeDo} // Your image for the "What We Do" section
      alt="Medical Services"
      className="rounded-lg shadow-lg w-full lg:max-w-sm"
    />
  </div>

  {/* Text Section */}
  <div className="lg:w-1/2 lg:pl-12 text-left">
    <h2 className="text-4xl font-bold mb-4 text-headingText">
      What We Do
    </h2>
    <p className="text-lg mb-4 text-primaryText">
      We Provide Best in Class Medical Services including EMR and EHR.
    </p>
    <p className="text-lg mb-4 text-primaryText">
      We appreciate your trust greatly. Our clients choose us and our services because they know we are the best.
    </p>
    <p className="text-lg mb-4 font-semibold text-primaryText">
      Mon-Fri: 8:00 am - 5:00 pm EST <br />
      Sat-Sun: <span className="text-red-600">Closed</span>
    </p>
    <button className="bg-btn border-2 border-btnBorder  text-white py-3 px-6 rounded hover:bg-btnHoverBg  transition duration-300">
      Book a Consultation
    </button>
  </div>
</section>


      {/* Solutions Section */}
      <section className="my-12 p-8 rounded-lg">
        <h2 className="text-3xl font-bold text-headingText text-center mb-6">Solutions</h2>
        <p className="text-lg text-center mb-8 text-primaryText">
          We Provide Top Quality HealthCare.
        </p>
        <div className="max-w-4xl mx-auto">
          <p className="text-center mb-8 text-primaryText">
            We offer exceptional customer and administrative services to all providers and groups in most of the medical fields across the USA, through a team of qualified medical professionals.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {/* Service 1 */}
            <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition duration-300 text-center">
              <img
                src={prm}
                alt="Patient Relations Management"
                className="w-full  mx-auto mb-4"
              />
              <h3 className="text-lg  font-semibold mb-2 text-primaryText">Patient Relations Management</h3>
            </div>

            {/* Service 2 */}
            <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition duration-300 text-center">
              <img
                src={mcs}
                alt="Medical Credentialing Services"
                className="w-full  mx-auto mb-4"
              />
              <h3 className="text-lg  font-semibold mb-2 text-primaryText">Medical Credentialing Services</h3>
            </div>

            {/* Service 3 */}
            <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition duration-300 text-center">
              <img
                src={bhc}
                alt="Behavioral Health Credentialing"
                className="w-full  mx-auto mb-4"
              />
              <h3 className="text-lg  font-semibold mb-2 text-primaryText">Behavioral Health Credentialing</h3>
            </div>

            {/* Service 4 */}
            <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition duration-300 text-center">
              <img
                src={mbs}
                alt="Medical Billing Services"
                className="w-full  mx-auto mb-4"
              />
              <h3 className="text-lg  font-semibold mb-2 text-primaryText">Medical Billing Services</h3>
            </div>
          </div>
        </div>
      </section>
      </div>
    </div>
  );
};

export default Home;
