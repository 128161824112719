import React, { useState } from 'react';
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin } from "react-icons/fa";
import { MdOutlinePhone, MdOutlineEmail } from "react-icons/md";
import { RiMenuUnfoldLine } from "react-icons/ri";
import logo from '../assets/images/logo.png';
import { navBarData } from '../assets/data/data';
import { NavLink } from 'react-router-dom';

const Header = () => {
    const [open, setOpen] = useState(false);

    const OpenMenu = () => {
        setOpen(!open);
    };

    return (
        <div className="">
            <div className='bg-primaryBackground '>
                <div className="container mx-auto px-2 py-6">
                    <div className="grid grid-cols-3 ">

                        {/* //contact====================== */}
                        <div className="sm:list-item list-none hidden my-auto">
                            <a href="tel:+13214831319" className='text-btn hover:text-btnHoverBg text-[17px]'>(321) 483-1319</a>
                            <br />
                            <a href="mailto:sales@fastops.net" className='text-btn hover:text-btnHoverBg text-[17px]'>sales@fastops.net</a>
                        </div>

                        {/* menu=================== */}
                        <div className="sm:hidden my-auto">
                            <RiMenuUnfoldLine 
                                className={`text-[25px] text-btn cursor-pointer transition-transform duration-300 ${open ? 'scale-x-[-1]' : ''}`} 
                                onClick={OpenMenu} 
                            />
                        </div>
                        <div className="w-[40%] mx-auto my-auto">
                            <NavLink to='/'>
                                <img src={logo} alt="" />
                            </NavLink>
                        </div>

                        {/* //socials=============== */}
                        <div className="ml-auto my-auto">
                            <div className=" flex justify-start items-center gap-2">
                                <a href="/"><FaFacebookSquare className='sm:text-[25px] text-[17px] hover:text-btnhover text-btn hover:text-btnHoverBg cursor-pointer' /></a>
                                <a href="/"><FaInstagramSquare className='sm:text-[25px] text-[17px] hover:text-btnhover text-btn hover:text-btnHoverBg cursor-pointer' /></a>
                                <a href="/"><FaLinkedin className='sm:text-[25px] text-[17px] hover:text-btnhover text-btn hover:text-btnHoverBg cursor-pointer' /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-headingText hidden sm:flex">
                <div className="container mx-auto">
                    <div className="flex justify-between items-center px-[10%] py-2">
                        {navBarData.map(links => (
                            <NavLink key={links.url} to={links.url} className='w-fit'>
                                <h2 className='text-[17px] text-btnhover hover:text-gray-50'>{links.title}</h2>
                            </NavLink>
                        ))}
                    </div>
                </div>
            </div>

            {open && (
                <div className="sm:hidden bg-primaryBackground bg-opacity-80 absolute w-[100%] z-40 min-h-screen" onClick={OpenMenu}>
                    {/* // phone screen code */}
                    <div className="bg-nav w-[70%] h-[90vh] flex flex-col justify-between">
                        <div className="flex flex-col items-start px-4 py-4">
                            {navBarData.map(link => (
                                <NavLink key={link.url} to={link.url} className='text-[17px] text-btnhover hover:text-gray-50 my-2 ' onClick={OpenMenu}>
                                    <span>- </span>{link.title} 
                                </NavLink>
                            ))}
                        </div>
                        <div className="flex-col ml-auto">
                            <a href="tel:+13214831319" className='text-headingText text-[17px] flex justify-center items-center gap-3  '> 
                                <span><MdOutlinePhone className='text-btnhover' /></span>(321) 483-1319
                            </a>
                            <a href="mailto:sales@fastops.net" className='text-headingText text-[17px] flex justify-center items-center gap-3 '> 
                                <span><MdOutlineEmail className='text-btnhover' /></span>sales@fastops.net
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Header;
