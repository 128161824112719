import React from 'react';
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin } from 'react-icons/fa';
import { MdOutlinePhone, MdOutlineEmail } from 'react-icons/md';

const Footer = () => {
  return (
    <footer className='bg-headingText text-white py-8'>
      <div className='container mx-auto px-4'>
        <div className='flex flex-col md:flex-row justify-between'>
          {/* Contact Information */}
          <div className='mb-4 md:mb-0'>
            <h2 className='text-xl font-bold mb-2'>Contact Us</h2>
            <p className='flex items-center'>
              <MdOutlinePhone className='mr-2' /> 
              <a href="tel:+13214831319" className='hover:underline text-white'>
                +1 (321) 483 1319
              </a>
            </p>
            <p className='flex items-center'>
              <MdOutlineEmail className='mr-2' /> 
              <a href="mailto:sales@fastops.net" className='hover:underline text-white'>
                sales@fastops.net
              </a>
            </p>
          </div>

          {/* Social Media Links */}
          <div className='flex space-x-4'>
            <a href="/" className='hover:text-btnHoverBg'>
              <FaFacebookSquare className='text-2xl' />
            </a>
            <a href="/" className='hover:text-btnHoverBg'>
              <FaInstagramSquare className='text-2xl' />
            </a>
            <a href="/" className='hover:text-btnHoverBg'>
              <FaLinkedin className='text-2xl' />
            </a>
          </div>
        </div>

        {/* Copyright */}
        <div className='mt-8 text-center'>
          <p className='text-sm'>
            &copy; {new Date().getFullYear()} Your Company Name. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
